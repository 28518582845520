import { render, staticRenderFns } from "./ListPangkat.vue?vue&type=template&id=112317ee&scoped=true&"
import script from "./ListPangkat.vue?vue&type=script&lang=js&"
export * from "./ListPangkat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112317ee",
  null
  
)

export default component.exports